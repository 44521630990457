@import "~expano-components/styles/status-colors.scss";
@import "~expano-components/styles/form.scss";
@import "~expano-components/styles/input.scss";
@import "~expano-components/styles/textarea.scss";
@import "~expano-components/styles/check.scss";
@import "~expano-components/styles/buttons.scss";
@import "~expano-components/styles/tree.scss";

@import "~expano-components/styles/popup.scss";
@import "~expano-components/styles/pagination.scss";
@import "~expano-components/styles/image-uploader.scss";
@import "~expano-components/styles/topbar.scss";
@import "~expano-components/styles/sidebar.scss";
@import "~expano-components/styles/main.scss";
@import "~expano-components/styles/login.scss";
@import "~expano-components/styles/change-app-modal.scss";
@import "~expano-components/styles/tradesk_label.scss";
@import "~expano-components/styles/page-panel.scss";
@import "~expano-components/styles/status-label.scss";

.page-panel {
  .info-holder__icon {
    svg {
      [fill] {
        fill: #5d78ff;
      }
    }
  }
}

.sidebar.collapsed {
  z-index: 10;
}
