.btn-preview {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #5d78ff, $alpha: 0.8);
    opacity: 0;
    transition: 0.2s;
    z-index: 1;
  }
  &::after {
    content: url("../../icons/zoom.svg");
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: 0.2s;
    transform: translate(-50%, -50%);
  }
  &:hover {
    &::before,
    &::after {
      opacity: 1;
    }
  }
}
.btn-preview-blank {
  min-width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: rgba(162, 165, 185, 0.4);
}
