.main-list {
  background-color: #fff;
  border: 1px solid #ebedf2;
  border-top: 0;
  border-bottom: 0;
  min-height: calc(100vh - 96px);
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-bottom: 1px solid #ebedf2;
    .header-title {
      padding: 9px 10px 6px 10px;
      display: inline-block;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      position: relative;
      color: #595d6e;
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #5d78ff;
      }
    }
    .header-aside {
      display: flex;
      align-items: center;
      padding: 5px 0;
    }
  }
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 200px);
    padding: 50px 0;
  }
  &__table {
    &.loading {
      opacity: 0.7;
    }
    padding: 0 10px;
    table {
      width: 100%;
      .col-actions {
        display: flex;
        justify-content: flex-end;
        .btn-icon {
          padding: 3px;
        }
        .btn {
          margin: 0 2px;
        }
      }
      .table-sort {
        border-bottom: 1px solid #ebedf2;
        th {
          padding: 16px 10px 13px;
          text-align: left;
          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #4e565f;
          }
          .extra {
            font-size: 11px;
            line-height: 13px;
            font-weight: 400;
            color: #0059a4;
          }
          .sort-button {
            padding: 0;
            margin: 0;
            display: inline-flex;
            align-items: center;
            span {
              margin-right: 5px;
            }
            &.asc {
              svg {
                transform: scaleY(-1);
              }
            }
            &.active {
              span {
                color: #5d78ff;
              }
              [fill] {
                fill: #5d78ff;
              }
            }
          }
        }
      }
      .table-filters {
        border-bottom: 1px solid #e9e9e9;
        th {
          padding: 10px 10px;
          text-align: left;
          .input-filter {
            input {
              outline: none;
              padding: 0 5px;
              font-weight: 400;
              font-size: 11px;
              line-height: 18px;
              border-radius: 2px;
              width: 100%;
              border: 1px solid #d9d9d9;
              color: #595d6e;
              transition: 0.2s;
              &:focus {
                border-color: #595d6e;
              }
              &::placeholder {
                color: #d7d7da;
              }
            }
          }
        }
        .filter-values {
          display: flex;
          align-items: center;
          flex-flow: wrap;
          width: calc(100% + 6px);
          margin: 5px -3px 0;
          .btn-remove-filter {
            display: inline-flex;
            margin: 3px;
            padding: 0px 5px;
            align-items: center;
            border: 1px solid #5d78ff;
            border-radius: 2px;
            transition: 0.2s;
            svg {
              margin-right: 3px;
            }
            span {
              font-weight: 500;
              font-size: 9px;
              line-height: 14px;
              color: #5d78ff;
            }
            &:hover {
              opacity: 0.9;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #e9e9e9;
          height: 40px;
          td {
            vertical-align: middle;
            padding: 6.5px 10px;
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            color: #57565b;
            a {
              color: #0067bd;
              font-weight: 500;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          &.with_navigate {
            transition: 0.2s;
            position: relative;
            &::after {
              content: "";
              transition: 0.2s;
              position: absolute;
              top: 0;
              right: -10px;
              bottom: 0;
              height: 100%;
              width: 10px;
              background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAgNDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMCAyMEwwIDBWNDBMMTAgMjBaIiBmaWxsPSIjRjBGM0ZGIi8+Cjwvc3ZnPgo=");
              background-size: 10px 100%;
              background-position: center;
              opacity: 0;
            }
            &:hover {
              background-color: rgba($color: #f0f3ff, $alpha: 0.6);
              &::after {
                opacity: 0.6;
              }
            }
            &.active {
              background-color: #f0f3ff;

              &::after {
                opacity: 1;
              }
            }
          }
          &.has_sub_list {
            .collapse-icon {
              svg {
                fill: none;
                transition: 0.2s;
              }
            }
            &.empty {
              .collapse-icon {
                opacity: 0.4;
              }
            }
            &.is_sub_list_open {
              background-color: #fafafa;
              border-bottom: 0;
              .collapse-icon {
                svg {
                  transform: scaleY(-1);
                }
              }
              & + tr {
                height: initial;
                background-color: #fafafa;
                & > td {
                  padding: 0 0 20px 38px;
                }
                .sub-list {
                  background-color: #fff;
                  border: 1px solid #ebedf2;
                  position: relative;
                  &::before {
                    content: "";
                    position: absolute;
                    width: 1px;
                    bottom: 0;
                    left: -24px;
                    top: -10px;
                    background-color: #ebedf2;
                  }
                  thead {
                    tr {
                      th {
                        vertical-align: middle;
                        padding: 5px 10px;
                        text-align: left;
                        font-weight: 700;
                        font-size: 11px;
                        line-height: 14px;
                        color: #b6b8bf;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.prices-table {
      table {
        .table-sort {
          background-color: #fff;
          position: sticky;
          top: 0;
          z-index: 2;
          .sort-group {
            display: flex;
            flex-flow: wrap;
            gap: 10px;
          }
          th {
            padding: 5px;
          }
        }
        .table-filters {
          background-color: #fff;
          position: sticky;
          top: 55px;
          z-index: 1;
          th {
            padding: 5px;
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid #e9e9e9;
            height: 40px;
            td {
              padding: 2px 10px;
              line-height: 15px;
            }
            .labels-group {
              margin: 2px 0;
              span {
                padding: 1px 5px;
                font-size: 8px;
                line-height: 14px;
                margin-right: 5px;
              }
              a{
                color: inherit;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
  &__empty {
    padding: 100px 20px;
    text-align: center;
    svg {
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
      color: #595d6e;
    }
  }
  &__bottom-action {
    padding-top: 10px;
    text-align: center;
  }
  &__pagination {
    padding: 14px 12px;
    .pagination {
      padding: 0;
    }
  }
  &__filters {
    padding: 5px 10px;
    border-bottom: 1px solid #ebedf2;
    .calendar-group {
      max-width: 100%;
    }
    .filters-grid {
      flex: 1;
      display: none;
      flex-flow: wrap;
      padding-bottom: 10px;
      &.open {
        display: flex;
      }
    }
    .visible-filters-grid {
      flex: 1;
      display: flex;
      flex-flow: wrap;
      gap: 20px;
      padding: 10px 0;
      .chart-item {
        flex: 1;
        .form-label {
          font-size: 13px;
          line-height: 20px;
          color: #646c9a;
        }
      }
    }
    .filters-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sort-group {
        display: flex;
        align-items: center;
        & > div:first-child {
          margin-right: 10px;
        }
      }
      .btn-collapse {
        font-size: 12px;
        line-height: 20px;
        color: #a2a5b9;
        padding: 5px;
        svg {
          max-width: 20px;
          margin-left: 4px;
        }
      }
    }
    .form-group {
      padding-top: 10px;
      flex-basis: 25%;
      max-width: 25%;
      padding-right: 20px;
      min-width: 300px;
      margin-bottom: 0;
      .inp-group {
        input {
          padding: 5px 10px;
          border-radius: 4px;
          border: 1px solid #e2e5ec;
          width: 100%;
          outline: none;
          font-size: 13px;
          line-height: 20px;
        }
      }
    }

    .tags-grid {
      flex: 4;
      &__wrapper {
        display: flex;
        flex-flow: wrap;
        gap: 4px;
        .btn-tag {
          padding: 2px 5px;
          color: #fff;
          font-size: 12px;
          line-height: 14px;
          background-color: #9c9c9c;
          &.active {
            background-color: #567cfb;
          }
        }
      }
    }
  }
}
