:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 1;
}

@import "~flag-icons/css/flag-icons.min.css";
@import "~react-markdown-editor-lite/lib/index.css";
@import "~react-toastify/dist/ReactToastify.css";
@import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
@import 'react-calendar/dist/Calendar.css';

@import "reset.scss";
@import "colors.scss";
@import "mixins.scss";
@import "typography.scss";
@import "table.scss";
@import "utils.scss";
@import "layout.scss";

@import "partials/checkbox.scss";
@import "partials/global_image_modal.scss";
@import "partials/product_image.scss";
@import "partials/labels.scss";
@import "partials/dashboard.scss";
@import "partials/error_box.scss";
@import "partials/order_status.scss";
@import "partials/order_status_select.scss";
@import "partials/tag.scss";
@import "partials/order_panel.scss";
@import "partials/waybill_panel.scss";
@import "partials/main_search.scss";
@import "partials/btn_more.scss";
@import "partials/chart_box.scss";

@import "expano_components.scss";
@import "partials/loaders.scss";

@import "partials/main_table.scss";
@import "partials/main_list.scss";
@import "partials/orders_layout.scss";

@import "partials/tabs.scss";
@import "partials/preview_button.scss";
@import "partials/progress-bar.scss";

.dzu-dropzone {
  &::-webkit-scrollbar {
    display: none;
  }
}
.popup.lg {
  max-width: 650px;
}

.popup.xxl {
  max-width: 1050px;
}

.tool-bar {
  display: none;
}

[class*="indicatorContainer"] {
  padding: 0 !important;
}

.form-group {
  [class*="indicatorContainer"] {
    padding: 0 10px !important;
  }
}

.se-container {
  z-index: 0;
}

.calendar-group {
  max-width: 270px;
  & > div {
    width: 100%;
  }
  .react-date-picker,
  .react-datetime-picker,
  .react-datetimerange-picker {
    z-index: 3;
    &__wrapper {
      width: 100%;
      padding: 0px;
      border-radius: 4px;
      border: none;
      border: 1px solid #e2e5ec;
      width: 100%;
      outline: none;
      transition: 0.2s;
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background: #f3f6f9;
      border-left: 1px solid #e2e5ec;
    }
    &__inputGroup {
      padding: 4px 8px;
      white-space: nowrap;
      input,
      span {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}

